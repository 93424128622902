import ButtonTwo from "../buttonTwo";
import NameSection from "../nameSection";

import {
  Container,
  ContainerButton,
  ContainerButtonTwo,
  Content,
  Date,
  DateEdition,
  Logo,
  LogoMobile,
  Month,
  NextEventOne,
  NextEventTwo,
  Slogan,
  SloganMobile,
} from "./styles";

import IconButton from "../../assets/icons/icon_button.svg";
import ImageSlogan from "../../assets/comandatuba/slogan.svg";
import ImageSloganMobile from "../../assets/comandatuba/slogan_mobile.svg";
import ImageComandatuba from "../../assets/comandatuba/logo_comandatuba.svg";

const NextEvent = () => {
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container>
      <Content>
        <NextEventOne>
          <NameSection
            textOne="PRÓXIMO"
            textTwo="EVENTO"
            className="next_event"
          />

          <DateEdition>
            <Month>JANEIRO</Month>
            <Date>19 à 26</Date>
          </DateEdition>

          <ContainerButton>
            <ButtonTwo
              icon={true}
              iconImage={IconButton}
              width="100%"
              onClick={() => scrollToSection("registration")}
            >
              INSCREVA-SE
            </ButtonTwo>
          </ContainerButton>
        </NextEventOne>
        <NextEventTwo>
          <Slogan src={ImageSlogan} alt="slogan" loading="lazy" />
          <SloganMobile src={ImageSloganMobile} alt="slogan" loading="lazy" />

          <Logo src={ImageComandatuba} alt="logo" loading="lazy" />
          <LogoMobile src={ImageComandatuba} alt="logo" loading="lazy" />

          <ContainerButtonTwo>
            <ButtonTwo
              icon={true}
              iconImage={IconButton}
              width="100%"
              onClick={() => scrollToSection("registration")}
            >
              INSCREVA-SE
            </ButtonTwo>
          </ContainerButtonTwo>
        </NextEventTwo>
      </Content>
    </Container>
  );
};

export default NextEvent;
