import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  max-height: 100%;
  width: 100%;
  background-color: #151515;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  top: 0px;
`;

export const ContentLogo = styled.figure`
  height: 90px;
  width: 90px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

export const NameEdition = styled.h1`
  color: white;
  font-size: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "GothamPro", sans-serif;
  font-weight: 400;
  width: 70%;
  padding: 5px 5px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const Title = styled.h1`
  font-family: "GothamPro", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  padding: 20px;
`;

export const ContainerInputs = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 300px;
`;

export const ContentInput = styled.div`
  gap: 10px;
  display: flex;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
`;

export const ContainerSteps = styled.div`
  background-color: #c6c6c6;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 35px;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 120px;

  @media (max-width: 475px) {
    top: 92px;
  }

  @media (max-height: 750px) {
    position: absolute;
  }
`;

export const Step = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "GothamPro", sans-serif;

  background-color: ${(props) => (props.active ? "#151515" : "#c6c6c6")};
  color: ${(props) => (props.active ? "white" : "#151515")};
`;

export const ContainerHealthCondition = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextHealthCondition = styled.label`
  font-family: "GothamPro", sans-serif;
  text-align: start;
`;

export const Registered = styled.div`
  font-family: "GothamPro", sans-serif;
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  margin-left: 5px;
`;

export const ContainerStepTwo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 29px;
  flex-direction: column;

  @media (max-height: 850px) {
    justify-content: end;
  }
`;

export const ContainerImages = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-height: 800px) {
    align-items: start;
  }
`;

export const NoDiscount = styled.label`
  font-family: "GothamPro", sans-serif;
`;
