import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  background-color: #151515;
  position: fixed;
  height: 100%;
  top: 0px;
  right: ${(props) => (props.sidebar ? "0" : "-100%")};
  width: 320px;
  animation: showSidebar 0.4s;
  z-index: 999999;

  > svg {
    color: white;
    width: 40px;
    height: 40px;
    margin-top: 18px;
    margin-right: 20px;
    right: 0px;
    top: 0px;
    position: fixed;
    cursor: pointer;
  }

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 320px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 80px;
  margin-top: 100px;
  width: 100%;
  height: 500px;
`;

export const Navigation = styled(NavLink)`
  text-decoration: none;
  & :hover {
    background-color: #f3b000;
  }
`;
