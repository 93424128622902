import styled from "styled-components";

export const TextLoading = styled.p`
  color: #000;
  font-family: "Montserrat", sans-serif;
`;

export const LoadingContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
