import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .swal2-container {
        z-index: 9999; 
    }
    .swal2-title {
        font-family: "GothamPro"
    }

    .swal2-html-container {
        font-family: "GothamPro"
    }

    .swal2-confirm {
        font-family: "GothamPro"
    }
`;
