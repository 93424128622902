import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-family: "GothamPro", sans-serif;
  font-weight: 600;
  color: white;
  width: 100%;
  align-items: center;

  @media (max-width: 475px) {
    justify-content: start !important;
  }
`;

export const Line = styled.div`
  width: 129px;
  height: 2px;
  background-color: #f3b000;
`;
