import {
  Container,
  Content,
  ContentInformations,
  Information,
  Subtitle,
  TableOne,
  Title,
} from "./styles";

import Table from "../../assets/comandatuba/tableOne.svg";
import TableMobile from "../../assets/comandatuba/tableOneMobile.svg";

import TableTwo from "../../assets/comandatuba/tableTwo.svg";
import TableTwoMobile from "../../assets/comandatuba/tableTwoMobile.svg";

const Tables = () => {
  return (
    <Container>
      <Content>
        <TableOne>
          <Title>VALORES</Title>
          <Subtitle>HOSPEDAGEM + BALSA</Subtitle>

          <img src={Table} alt="tabela 1" loading="lazy" />
          <img src={TableMobile} alt="table 1" loading="lazy" />
          <ContentInformations>
            <Information>
              *CHD até 11 anos **Sujeito a disponibilidade
            </Information>
            <Information>
              Verifique fotos e detalhes das acomodações{" "}
              <a
                href="https://www.transamericacomandatuba.com.br/acomodacoes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                clicando aqui
              </a>
            </Information>
          </ContentInformations>
        </TableOne>
        <TableOne>
          <Title>VALORES</Title>
          <Subtitle>AÉREO + HOSPEDAGEM + TRANSFER + BALSA</Subtitle>

          <img src={TableTwo} alt="tabela 2" loading="lazy" />
          <img src={TableTwoMobile} alt="table 2" loading="lazy" />

          <ContentInformations>
            <Information>
              *CHD até 11 anos **Sujeito a disponibilidade
            </Information>
          </ContentInformations>
        </TableOne>
      </Content>
    </Container>
  );
};

export default Tables;
