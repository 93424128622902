import { Container, Icon } from "./styles";

const ButtonTwo = ({
  onClick,
  children,
  width,
  icon = false,
  backgroundColor = "#f3b000",
  iconImage,
  fontSize,
  heigth,
  type,
  lineHeight,
  letterSpacing,
  color,
  className,
}) => {
  return (
    <Container
      onClick={onClick}
      type={type}
      style={{
        width: width,
        backgroundColor: backgroundColor,
        fontSize: fontSize,
        height: heigth,
        lineHeight: lineHeight,
        letterSpacing: letterSpacing,
        color: color,
      }}
      className={className}
    >
      {icon && <Icon alt="icone_botao" src={iconImage} />}
      {children}{" "}
    </Container>
  );
};

export default ButtonTwo;
