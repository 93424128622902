import {
  Container,
  Content,
  EventDate,
  EventDescription,
  EventNote,
  EventPeriod,
  Logo,
} from "./styles";

import LogoEvent from "../../assets/comandatuba/logo_comandatuba._and_caioba.svg";

const EventBanner = () => {
  return (
    <Container>
      <Content>
        <Logo
          src={LogoEvent}
          alt="logo"
          loading="lazy"
          aria-label="Logo do evento"
        />
        <EventDate>JANEIRO DE 2025</EventDate>
        <EventPeriod>19 à 26</EventPeriod>

        <EventDescription>
          A melhor semana da sua vida começa agora! Amarre as chuteiras, olho na
          bola e bora jogar!
        </EventDescription>
        <EventNote>
          *O Caioba Edição Comandatuba acontece durante uma semana inteira no
          hotel localizado na Bahia.
        </EventNote>
      </Content>
    </Container>
  );
};

export default EventBanner;
