import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextField } from "@mui/material";
import Button from "../../components/button";
import LoadingForm from "../../components/loadingForm";
import MaskedTextField from "../../components/maskedField";
import Swal from "sweetalert2";

import { Container, Form, Header } from "./styles";

import Image from "../../assets/pre_sale/pre_sale.svg";

const PreSale = () => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://caiobasc.com.br/sendForm/preSale.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const dataResponse = await response.json();

      if (dataResponse.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: dataResponse.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });
        return;
      }
      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.log("Erro: ", error);

      Swal.fire({
        title: "Erro ao enviar formulário",
        html: error,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Container>
      {loading && <LoadingForm />}
      <Header src={Image} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          required
          label="Nome"
          variant="standard"
          fullWidth
          sx={{ mb: 2 }}
          {...register("name")}
          name="name"
        />
        <TextField
          required
          label="Email"
          variant="standard"
          fullWidth
          sx={{ mb: 2 }}
          {...register("email", {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Endereço de email inválido",
            },
          })}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : null}
        />

        <Controller
          control={control}
          name="phone"
          rules={{
            required: true,
            validate: (value) =>
              validateLength(value, 11) || "Telefone incompleto",
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <MaskedTextField
              mask="(99) 99999-9999"
              label="Telefone"
              value={value}
              onChange={onChange}
              fullWidth
              sx={{ mb: 2 }}
              error={!!error}
              helperText={error ? error.message : null}
              variant="standard"
            />
          )}
        />
        <TextField
          required
          label="Idade do(s) filho(s)"
          variant="standard"
          fullWidth
          sx={{ mb: 2 }}
          {...register("age")}
          name="age"
        />

        <Button
          backgroundColor="#F3B000"
          width={316}
          color="#151515"
          type="submit"
        >
          Enviar
        </Button>
      </Form>
    </Container>
  );
};

export default PreSale;
