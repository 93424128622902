import { LoadingContent, TextLoading } from "./styles";

const LoadingForm = () => {
  return (
    <LoadingContent>
      <TextLoading style={{ color: "#fff", fontSize: 24 }}>
        Carregando...
      </TextLoading>
    </LoadingContent>
  );
};

export default LoadingForm;
