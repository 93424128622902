import Root from "./pages/routes";
import Reset from "./styles/reset";

function App() {
  return (
    <>
      <Reset />
      <Root />
    </>
  );
}

export default App;
