import React from "react";
import styled, { keyframes } from "styled-components";

import Line from "../../assets/line.webp";

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const ScrollingBackgroundContainer = styled.div`
  width: 100%;
  height: 60px;
  overflow: hidden;
  position: relative;
  background: url(${Line}) repeat-x;
  background-size: contain;
  margin-top: 51px;
  margin-bottom: 51px;

  @media (max-width: 475px) {
    height: 40px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: inherit;
    animation: ${scroll} 40s linear infinite;

    @media (max-width: 475px) {
      animation: ${scroll} 20s linear infinite;
    }
  }
`;

const ScrollingBackground = () => {
  return <ScrollingBackgroundContainer />;
};

export default ScrollingBackground;
