import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  background-color: #151515;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 20px;
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableOne = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;

    @media (max-width: 425px) {
      display: none;
    }

    &:nth-of-type(2) {
      display: none;

      @media (max-width: 425px) {
        display: flex;
      }
    }
  }
`;

export const Title = styled.h1`
  font-family: "GothamPro", sans-serif;
  color: #f3b000;
  font-size: 39px;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Subtitle = styled.h2`
  font-family: "GothamPro", sans-serif;
  color: white;
  margin-bottom: 55px;
  font-size: 27px;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const ContentInformations = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 10px;
  margin-bottom: 30px;

  a {
    color: #f3b000;
    text-decoration: none;
  }
`;

export const Information = styled.span`
  font-size: 16px;
  color: white;
  font-family: "GothamPro", sans-serif;
`;
