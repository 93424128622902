import React from "react";
import styled from "styled-components";

import LogoImage from "../../assets/pix.svg";
import Icon from "../../assets/icons/icon_pix.svg";

const CardContainer = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 367px;
  height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: auto;
  margin-bottom: 10px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const ContainerButton = styled.button`
  background-color: #f3b000;
  color: white;
  height: 42px;
  border: none;
  width: 264px;
  font-size: 19px;
  cursor: pointer;
  font-family: "GothamPro";
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  label {
    cursor: pointer;
  }
`;

const IconPix = styled.img``;

const Card = () => {
  return (
    <CardContainer>
      <Logo src={LogoImage} alt="logo_pix" />
      <ContainerButton>
        <IconPix src={Icon} />
        <label>Pagamento via Pix</label>
      </ContainerButton>
    </CardContainer>
  );
};

export default Card;
