import React from "react";
import {
  Amount,
  Container,
  Content,
  Price,
  TitleRegistration,
  Total,
  Value,
  Values,
} from "./styles";

const CardRegistration = ({ amount, amountRegistration }) => {
  return (
    <Container>
      <Content>
        <Amount>{amount}</Amount>
      </Content>
      <Values>
        {amountRegistration === 1 && (
          <div>
            <TitleRegistration>INSCRIÇÃO ÚNICA</TitleRegistration>
            <Price>R$ 4.400</Price>
          </div>
        )}
        {amountRegistration === 2 && (
          <div>
            <TitleRegistration>INSCRIÇÃO 1</TitleRegistration>
            <Price>R$ 4.400</Price>
            <TitleRegistration>INSCRIÇÃO 2</TitleRegistration>
            <Price>R$ 3.740</Price>
            <Total>Total</Total>
            <Value>R$ 8.140</Value>
          </div>
        )}
        {amountRegistration === 3 && (
          <div>
            <TitleRegistration>INSCRIÇÃO 1</TitleRegistration>
            <Price>R$ 4.400</Price>
            <TitleRegistration>INSCRIÇÃO 2</TitleRegistration>
            <Price>R$ 3.740</Price>
            <TitleRegistration>INSCRIÇÃO 3</TitleRegistration>
            <Price>R$ 3.366</Price>

            <Total>Total</Total>
            <Value>R$ 11.506</Value>
          </div>
        )}
      </Values>
    </Container>
  );
};

export default CardRegistration;
