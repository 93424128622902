import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { cpf } from "cpf-cnpj-validator";
import Swal from "sweetalert2";

import MaskedTextField from "../maskedField";
import LoadingForm from "../loadingForm";
import ButtonTwo from "../buttonTwo";
import Card from "../card";
import FormSelect from "./select/";

import { CiCircleRemove } from "react-icons/ci";
import { FaPlusCircle } from "react-icons/fa";
import { IoRemoveCircle } from "react-icons/io5";

import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import {
  Container,
  ContainerButton,
  ContainerHealthCondition,
  ContainerImages,
  ContainerInputs,
  ContainerStepTwo,
  ContainerSteps,
  Content,
  ContentInput,
  ContentLogo,
  Header,
  Logo,
  NameEdition,
  NoDiscount,
  Registered,
  Step,
  TextHealthCondition,
  Title,
} from "./styles";

import LogoCaioba from "../../assets/logo_caioba.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: "900px",
  height: "100%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  boxShadow: 20,
  p: 1,
  overflowY: "auto",
  borderRadius: 3,
};

const FormData = ({ openModal, handleClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    unregister,
    register,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [registeredList, setRegisteredList] = useState([{ id: 1 }]);
  const [checkedList, setCheckedList] = useState({});

  const tshirtSizesFather = ["P", "M", "G", "GG"];
  const tshirtSizesMother = ["P", "M", "G", "GG"];
  const ageRegistered = ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14"];

  const sizeTshirtRegistered = [
    "6",
    "8",
    "10",
    "12",
    "PP(adulto)",
    "P(adulto)",
    "M(adulto)",
    "G(adulto)",
  ];

  const sizeShortRegistered = [
    "6",
    "8",
    "10",
    "12",
    "PP(adulto)",
    "P(adulto)",
    "M(adulto)",
    "G(adulto)",
  ];

  const boots = [
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
  ];

  const agency = [
    "Caioba",
    "7 Mares",
    "BORALA Turismo",
    "Bocatur",
    "Camilla Mattar Viagens",
    "Carla Vieira Tours",
    "Carol Cordeiro Viagens",
    "Droptur",
    "Escape Tour",
    "Eventho Turismo",
    "Gentile Travel",
    "GF Turismo",
    "Golf Travel",
    "HOSTWAY TRAVEL",
    "LRB Viagens",
    "Love Trips",
    "MMA VIAGENS",
    "My Tour Turismo",
    "Novitá Turismo",
    "Patrícia Saraiva Travel",
    "PHD Travel",
    "Set Travel",
    "SN3 Viagens",
    "Viagens e Descobertas",
    "World Destiny",
  ];

  const valueMap = {
    1: 440000,
    2: 814000,
    3: 1150600,
  };

  const postRequest = async (url, body) => {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (data.success === false) {
      throw new Error("Erro ao enviar formulário");
    }
    return data;
  };

  const onSubmit = async (formData, event) => {
    if (step !== 2) {
      event.preventDefault();
      setStep(step + 1);
      return false;
    }

    setLoading(true);

    const value = valueMap[registeredList.length] || 0;
    const formattedCPF = formData.cpf.replace(/[.-]/g, "");

    try {
      const data = {
        ...formData,
        cpfFormatted: formattedCPF,
        value: value,
        dateEvent: "19 a 26 de Janeiro 2025",
      };

      const dataForm = await postRequest(
        "https://caiobasc.com.br/sendForm/sendForm2.php",
        data
      );

      if (dataForm && dataForm.secure_url && dataForm.success === true) {
        const dataFormEmail = {
          ...formData,
          eventName: "Formulário Comandatuba",
        };

        await postRequest(
          "https://caiobasc.com.br/sendForm/sendEmail2.php",
          dataFormEmail
        );

        window.location.href = dataForm.secure_url;
      }
    } catch (error) {
      Swal.fire({
        title: `Erro ao enviar formulário`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const isValidCPF = (value) => {
    if (typeof value !== "string" || value.length === 0) {
      return true;
    }

    const isValid = cpf.isValid(value);
    return isValid || "CPF inválido";
  };

  const addRegistered = () => {
    if (registeredList.length < 3) {
      setRegisteredList([...registeredList, { id: registeredList.length + 1 }]);
    }
  };

  const removeRegistered = (id) => {
    setRegisteredList((registered) =>
      registered.filter((item) => item.id !== id)
    );
    unregister(`registered[${id - 1}].name`);
    unregister(`registered[${id - 1}].age`);
    unregister(`registered[${id - 1}].gender`);
    unregister(`registered[${id - 1}].tshirt`);
    unregister(`registered[${id - 1}].short`);
    unregister(`registered[${id - 1}].boots`);
    unregister(`registered[${id - 1}].fatherProfession`);
    unregister(`registered[${id - 1}].motherProfession`);
    unregister(`registered[${id - 1}].birthdate`);
    unregister(`registered[${id - 1}].teamRegistered`);
    unregister(`registered[${id - 1}].school`);
    unregister(`registered[${id - 1}].healthCondition`);
  };

  const handleCheckboxChange = (id, checked) => {
    setCheckedList({
      ...checkedList,
      [id]: checked,
    });
  };

  return (
    <Container>
      {loading && <LoadingForm />}
      <Modal
        keepMounted
        open={openModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Content>
            <CiCircleRemove
              size={35}
              onClick={handleClose}
              color="#c6c6c6"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 999999,
                cursor: "pointer",
              }}
            />
            <Header>
              <ContentLogo>
                <Logo src={LogoCaioba} />
              </ContentLogo>
              <NameEdition>Comandatuba - 19 a 26 de Janeiro 2025</NameEdition>
            </Header>
            <ContainerSteps>
              <Step
                style={{
                  backgroundColor: step === 1 ? "#151515" : "#c6c6c6",
                  color: step === 1 ? "white" : "#151515",
                }}
              >
                Passo 1
              </Step>
              <Step
                style={{
                  backgroundColor: step === 2 ? "#151515" : "#c6c6c6",
                  color: step === 2 ? "white" : "#151515",
                }}
              >
                Passo 2
              </Step>
            </ContainerSteps>

            {step === 1 && (
              <ContainerInputs>
                <Title>Informações do responsável</Title>

                <ContentInput>
                  <TextField
                    name="name"
                    required
                    label="Nome Completo"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    {...register("name")}
                  />

                  <TextField
                    label="Email"
                    required
                    variant="outlined"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Endereço de email inválido",
                      },
                    })}
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : null}
                    sx={{ mb: 2 }}
                  />
                </ContentInput>

                <ContentInput>
                  <Controller
                    control={control}
                    name="rg"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 9) || "RG incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="99.999.999-9"
                        label="RG"
                        value={value || ""}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="cpf"
                    rules={{
                      required: true,
                      validate: isValidCPF,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="999.999.999-99"
                        label="CPF"
                        value={value || ""}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>

                <ContentInput>
                  <TextField
                    name="birthdate"
                    label="Data de Nascimento"
                    required
                    type="date"
                    fullWidth
                    sx={{ mb: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("birthdate")}
                  />

                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 11) || "Telefone incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="(99) 99999-9999"
                        label="Telefone"
                        value={value || ""}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>
                <ContentInput>
                  <FormSelect
                    array={tshirtSizesFather}
                    control={control}
                    label="Tam. Camiseta (Pai)"
                    nameSelect="tshirtFather"
                  />

                  <FormSelect
                    array={tshirtSizesMother}
                    control={control}
                    label="Tam. Camiseta (Mãe)"
                    nameSelect="tshirtMother"
                  />
                </ContentInput>
                <ContentInput>
                  <TextField
                    name={`fatherProfession`}
                    id="fatherProfession"
                    required
                    label="Area de atuação do Pai"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    {...register(`fatherProfession`)}
                  />

                  <TextField
                    required
                    name={`motherProfession`}
                    id="motherProfession"
                    label="Area de atuação da Mãe"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    {...register(`motherProfession`)}
                  />
                </ContentInput>

                <ContentInput>
                  <TextField
                    label="Endereço"
                    name="address"
                    required
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    {...register("address")}
                  />

                  <Controller
                    control={control}
                    name="cep"
                    rules={{
                      required: true,
                      validate: (value) =>
                        validateLength(value, 8) || "CEP incompleto",
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="99999-999"
                        label="CEP"
                        value={value || ""}
                        onChange={onChange}
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>

                <ContentInput>
                  <TextField
                    label="Cidade"
                    name="city"
                    required
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    {...register("city")}
                  />

                  <TextField
                    label="Estado"
                    name="state"
                    required
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    {...register("state")}
                  />
                </ContentInput>

                <ContentInput>
                  <FormSelect
                    array={agency}
                    control={control}
                    label="Agências"
                    nameSelect="agency"
                  />
                </ContentInput>

                <Title>Informações do cônjuge</Title>

                <ContentInput>
                  <TextField
                    name="nameSpouse"
                    variant="outlined"
                    label="Nome"
                    placeholder="Nome"
                    fullWidth
                    {...register("nameSpouse")}
                  />

                  <Controller
                    control={control}
                    name="cpfSpouse"
                    rules={{
                      validate: isValidCPF,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MaskedTextField
                        mask="999.999.999-99"
                        label="CPF"
                        value={value || ""}
                        onChange={onChange}
                        fullWidth
                        required={false}
                        sx={{ mb: 2 }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </ContentInput>

                <>
                  <Title>Informações do Inscrito</Title>
                  {registeredList.map((registered, index) => (
                    <div key={registered.id}>
                      <Registered>{registered.id}º Inscrito</Registered>

                      <ContentInput>
                        <TextField
                          name={`registered[${index}].name`}
                          label="Nome Completo"
                          variant="outlined"
                          fullWidth
                          sx={{ mb: 2 }}
                          required
                          {...register(`registered[${index}].name`)}
                        />

                        <FormSelect
                          array={ageRegistered}
                          control={control}
                          label="Idade"
                          nameSelect={`registered[${index}].age`}
                        />
                      </ContentInput>
                      <ContentInput>
                        <FormSelect
                          array={["Masculino", "Feminino"]}
                          control={control}
                          label="Sexo"
                          nameSelect={`registered[${index}].gender`}
                        />

                        <FormSelect
                          array={sizeTshirtRegistered}
                          control={control}
                          label="Tamanho da Camiseta"
                          nameSelect={`registered[${index}].tshirt`}
                        />
                      </ContentInput>
                      <ContentInput>
                        <FormSelect
                          array={sizeShortRegistered}
                          control={control}
                          label="Tamanho do Short"
                          nameSelect={`registered[${index}].short`}
                        />

                        <FormSelect
                          array={boots}
                          control={control}
                          label="Tamanho da chuteira"
                          nameSelect={`registered[${index}].boots`}
                        />
                      </ContentInput>

                      <ContentInput>
                        <TextField
                          label="Data de Nascimento"
                          name={`registered[${index}].birthdate`}
                          required
                          type="date"
                          fullWidth
                          sx={{ mb: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`registered[${index}].birthdate`)}
                        />

                        <TextField
                          label="Time do coração"
                          name={`registered[${index}].teamRegistered`}
                          required
                          variant="outlined"
                          fullWidth
                          sx={{ mb: 2 }}
                          {...register(`registered[${index}].teamRegistered`)}
                        />
                      </ContentInput>

                      <ContentInput>
                        <TextField
                          label="Escola que estuda"
                          name={`registered[${index}].school`}
                          required
                          variant="outlined"
                          fullWidth
                          sx={{ mb: 2 }}
                          {...register(`registered[${index}].school`)}
                        />
                      </ContentInput>

                      <ContentInput style={{ marginTop: 10 }}>
                        <Controller
                          control={control}
                          name={`registered[${index}].healthCondition`}
                          render={({ field: { value, onChange } }) => (
                            <ContainerHealthCondition>
                              <TextHealthCondition>
                                Condição médica especial?
                              </TextHealthCondition>

                              <FormGroup
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!!checkedList[registered.id]}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          registered.id,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label="Sim"
                                />
                                {!!checkedList[registered.id] && (
                                  <TextField
                                    label="Qual?"
                                    name={`registered[${index}].healthCondition`}
                                    required
                                    variant="outlined"
                                    value={value || ""}
                                    onChange={onChange}
                                  />
                                )}
                              </FormGroup>
                            </ContainerHealthCondition>
                          )}
                        />
                      </ContentInput>

                      {registered.id === 1 ? (
                        <div
                          style={{
                            width: "100%",
                            marginBottom: 30,
                          }}
                        />
                      ) : (
                        registeredList.length > 1 && (
                          <IoRemoveCircle
                            size={30}
                            color="red"
                            onClick={() => removeRegistered(registered.id)}
                            style={{
                              cursor: "pointer",
                              marginBottom: 10,
                            }}
                          />
                        )
                      )}
                    </div>
                  ))}
                  {registeredList.length < 3 && (
                    <span
                      style={{
                        color: "#151515",
                        fontFamily: "GothamPro",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                        marginBottom: 30,
                      }}
                      onClick={addRegistered}
                    >
                      <FaPlusCircle color="green" /> Adicionar Inscrito
                    </span>
                  )}
                  <ContentInput
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <TextField
                      id="cupom"
                      name="cupom"
                      label="Cupom de desconto"
                      variant="outlined"
                      sx={{ mb: 2, height: 40 }}
                      fullWidth
                      {...register("cupom")}
                    />

                    <NoDiscount>* Descontos não acumulativos!</NoDiscount>
                  </ContentInput>

                  <ContainerButton>
                    <ButtonTwo
                      heigth={35}
                      width={300}
                      fontSize={18}
                      letterSpacing={0}
                    >
                      Ir para pagamento
                    </ButtonTwo>
                  </ContainerButton>
                </>
              </ContainerInputs>
            )}

            {step === 2 && (
              <ContainerStepTwo>
                <ContainerImages>
                  <Card onClick={handleSubmit(onSubmit)} />
                </ContainerImages>
                <ButtonTwo
                  width={135}
                  heigth={35}
                  onClick={() => setStep(step - 1)}
                  backgroundColor="#C6C6C6"
                  letterSpacing={0}
                >
                  Voltar
                </ButtonTwo>
              </ContainerStepTwo>
            )}
          </Content>
        </Box>
      </Modal>
    </Container>
  );
};

export default FormData;
