import styled from "styled-components";

import Background from "../../assets/family_soccer_camp.webp";
import BackgroundMobile from "../../assets/family_soccer_camp_2.webp";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContainerFamilySoccerCamp = styled.section`
  max-width: 100%;
  width: 100%;
  height: 701px;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: end;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  position: relative;
  margin-bottom: 41px;

  @media (max-width: 950px) {
    background-position: 150px;
  }

  @media (max-width: 750px) {
    background-position: 80px;
  }

  @media (max-width: 650px) {
    background-image: url(${BackgroundMobile});
    height: 300px;
    background-position: right;
    margin-bottom: 20px;
  }
`;

export const TextSoccerCamp = styled.h1`
  font-size: 60px;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  color: white;
  width: 495px;
  height: 216px;
  position: absolute;
  left: 150px;

  @media (max-width: 900px) {
    left: 30px;
  }

  @media (max-width: 750px) {
    left: 20px;
    font-size: 50px;
  }

  @media (max-width: 650px) {
    font-size: 21px;
    width: 177px;
    height: 52px;
  }
`;

export const ContainerAboutUs = styled.section`
  max-width: 100%;
  width: 100%;
  height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #151515;
  margin-bottom: 58px;

  @media (max-width: 950px) {
    height: 100%;
  }
`;

export const ContentAboutUs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-right: 8%;

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 1400px) {
    margin-right: 30px;
  }

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    margin-left: 0px;
    margin-right: 0px;
    gap: 50px;
  }
`;

export const TextAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 40%;
  color: white;
  height: 100%;
  padding-left: 20px;

  .NameSection {
    @media (max-width: 475px) {
      padding: 20px 20px;
    }
  }

  label {
    font-size: 36px;
    font-family: "GothamPro", sans-serif;
    font-weight: 600;
    width: 409px;
    height: 84px;
    text-align: end;

    @media (max-width: 950px) {
      text-align: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 44px;
      font-size: 34px;
    }

    @media (max-width: 1250px) {
      padding: 20px 20px;
    }
  }

  p {
    font-size: 16px;
    font-family: "GothamPro", sans-serif;
    font-weight: 350;
    text-align: end;
    width: 371px;
    height: 73px;

    @media (max-width: 1250px) {
      padding: 20px 20px;
    }

    @media (max-width: 950px) {
      text-align: start;
      width: 100%;
      margin-bottom: 18px;
    }

    @media (max-width: 475px) {
      line-height: 19px;
    }
  }

  cite {
    font-size: 16px;
    font-weight: 500;
    color: #787878;
    font-family: "GothamPro", sans-serif;

    @media (max-width: 1250px) {
      padding: 20px 20px;
    }

    @media (max-width: 950px) {
      width: 100%;
      align-items: flex-start;
    }

    @media (max-width: 475px) {
      margin-top: 20px;
    }
  }

  @media (max-width: 950px) {
    width: 100%;
    align-items: flex-start;
  }

  @media (max-width: 475px) {
    padding-left: 0px;
  }
`;

export const ContainerEditions = styled.section`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 750px) {
    height: 100%;
  }
`;

export const ContentEditions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17.5px;
  font-weight: 400;
  font-family: "GothamPro", sans-serif;
  color: #7d7d7d;
  margin-bottom: 55px;
  gap: 15px;

  & :nth-child(1) {
    font-size: 36px;
    font-weight: 600;
    font-family: "GothamPro", sans-serif;
    color: #000000;

    @media (max-width: 475px) {
      margin-bottom: 18px;
      font-size: 25px;
    }
  }

  & :nth-child(2) {
    letter-spacing: 6px;

    @media (max-width: 475px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
    text-align: center;
  }

  @media (max-width: 475px) {
    margin-bottom: 20px;
  }
`;

export const ContainerRegistrations = styled.section`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #151515;
`;

export const ContentRegistrations = styled.div`
  width: 1200px;
  height: 100%;
  padding-top: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;

  span {
    font-size: 29px;
    font-weight: 600;
    font-family: "GothamPro", sans-serif;
    color: white;

    @media (max-width: 475px) {
      font-size: 20px;
    }
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 55px;
  margin-bottom: 55px;

  @media (max-width: 1125px) {
    justify-items: center;
    gap: 10px;
    align-content: center;
  }

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 475px) {
    margin-bottom: 0px;
  }
`;

export const IconRegistrations = styled.img`
  width: 45px;
  height: 37px;
`;

export const DescriptionRegistrations = styled.label`
  font-size: 24px;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  color: white;
  width: 85%;
  text-align: center;
  margin-top: 55px;
  margin-bottom: 55px;

  span {
    color: #f3b000;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 920px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    font-size: 16px;
  }
`;

export const IconArrow = styled.img`
  position: absolute;
  left: 150px;
  bottom: 120px;
  width: 46px;
  height: 94px;

  @media (max-width: 900px) {
    left: 30px;
  }

  @media (max-width: 650px) {
    bottom: 30px;
    width: 45px;
    height: 45px;
    left: 10px;
  }
`;

export const ContainerRegisteredButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;

  div {
    width: 343px;

    .buttonTwo {
      font-size: 16px;
    }

    @media (max-width: 375px) {
      width: 100%;
    }
  }

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerButtonRegisred = styled.div`
  width: 100%;
  max-width: 356px;

  & :nth-child(2) {
    @media (max-width: 475px) {
      font-size: 18px;
      letter-spacing: 0px;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const ContainerButtonRegisteredMobile = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin-bottom: 37px;
  }
`;
