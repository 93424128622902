import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.img`
  margin-bottom: 20px;
  max-width: 768px;
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 573px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 600px) {
    padding: 10px 10px;
  }

  button {
    margin-top: 20px;
  }
`;
