import { Container, Content, Description, Logo } from "./styles";

import LogoEvent from "../../assets/comandatuba/logo_comandatuba_white.svg";

const DescriptionEvent = () => {
  return (
    <Container id="event">
      <Content>
        <Logo src={LogoEvent} alt="logo" loading="lazy" />

        <Description>
          O <strong>Hotel Transamérica Comandatuba</strong> é um dos destinos
          mais exclusivos do Brasil, localizado em uma ilha paradisíaca no sul
          da Bahia.
        </Description>
        <br />
        <Description>
          Oferecendo uma experiência única de lazer e conforto, o resort é
          conhecido por suas amplas instalações, que incluem campos de golfe,
          quadras de tênis, esportes aquáticos, e claro, sua praia particular.
          Além disso, o hotel é muito procurado para eventos familiares e
          empresariais, sendo um cenário perfeito para momentos inesquecíveis em
          meio à natureza exuberante.
        </Description>
        <br />
        <Description>
          O atendimento de excelência e a culinária de primeira qualidade
          completam a experiência, tornando o Transamérica Comandatuba uma
          escolha ideal para quem busca relaxamento e sofisticação em um
          ambiente acolhedor.
        </Description>
      </Content>
    </Container>
  );
};

export default DescriptionEvent;
