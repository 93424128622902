import React from "react";

import Button from "../button";

import {
  ContainerButton,
  StyledBox,
  StyledModal,
  TextWarning,
  Warning,
} from "./styles";

import WarningIcon from "../../assets/icons/warning.svg";

const WarningModal = ({ open, handleClose }) => {
  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledBox>
        <Warning>
          <img src={WarningIcon} alt="aviso" />
          ATENÇÃO
        </Warning>

        <TextWarning>
          <span>
            Para se inscrever no Caioba Comandatuba 2025 é necessário primeiro
            fazer as reservas de hotel.
          </span>
          <span>
            Entre em contato pelo nosso WhatsApp para fazer suas reservas.
          </span>
        </TextWarning>

        <ContainerButton>
          <Button
            onClick={handleClose}
            backgroundColor="#f3b000"
            height={57}
            borderRadius={0}
          >
            Fechar
          </Button>
        </ContainerButton>
      </StyledBox>
    </StyledModal>
  );
};

export default WarningModal;
