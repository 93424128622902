import { Container } from "./styles";

const Button = ({
  onClick,
  children,
  backgroundColor,
  disabled,
  width,
  color,
  height,
  borderRadius,
}) => {
  return (
    <Container
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        width: width,
        color: color,
        height: height,
        borderRadius,
      }}
      disabled={disabled}
    >
      {children}{" "}
    </Container>
  );
};

export default Button;
