import { Container, Line } from "./styles";

const NameSection = ({ textOne, textTwo, style, className }) => {
  return (
    <Container style={style} className={className}>
      <span>{textOne}</span>
      <Line alt="linha" />
      <span>{textTwo}</span>
    </Container>
  );
};

export default NameSection;
