import styled from "styled-components";

export const Container = styled.button`
  background: #ea8126;
  width: 100%;
  height: 40px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: white;
  font-weight: 800;
  border-radius: 5px;
`;
