import styled from "styled-components";

export const Container = styled.button`
  height: 74px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  font-family: "GothamPro", sans-serif;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 356px;
  padding: 10px 10px;
  letter-spacing: 4px;
  transition: transform 0.2s ease;

  @media (max-width: 500px) {
    width: 246px;
    height: 60px;
    font-size: 18px;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

export const Icon = styled.img`
  width: 46px;
  height: 46px;
`;
