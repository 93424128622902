import React, { useState, useRef } from "react";
import styled from "styled-components";

import Video from "../../assets/abertura_caioba_2024.mp4";
import Background from "../../assets/background_video.webp";

const Container = styled.div`
  background-color: #151515;
  width: 100%;
  max-height: 590px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const VideoPlayer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    max-width: 150px;
    height: auto;
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const PlayIcon = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  max-width: 150px;
  height: auto;
  opacity: 1;
  transition: opacity 0.5s ease;
`;

const CustomVideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const backgroundRef = useRef(null);
  const playIconRef = useRef(null);

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      backgroundRef.current.style.opacity = "0";
      playIconRef.current.style.opacity = "0";
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      playIconRef.current.style.opacity = "1";
      setIsPlaying(false);
    }
  };

  const handleVideoEnd = () => {
    backgroundRef.current.style.opacity = "1";
    playIconRef.current.style.opacity = "1";
    setIsPlaying(false);
  };

  return (
    <Container>
      <VideoPlayer onClick={handleVideoClick}>
        <img ref={backgroundRef} src={Background} alt="Imagem de fundo" />
        <video
          ref={videoRef}
          id="myVideo"
          preload="metadata"
          onEnded={handleVideoEnd}
        >
          <track kind="captions" src={Video} srcLang="pt" label="Português" />
          <source src={Video} type="video/mp4" />
        </video>
        <PlayIcon
          ref={playIconRef}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 150 150"
          className="play-icon"
        >
          <circle cx="75" cy="75" r="75" fill="#F3B000" />
          <path d="M50 40 L110 75 L50 110 Z" fill="#FFFFFF" />
        </PlayIcon>
      </VideoPlayer>
    </Container>
  );
};

export default CustomVideoPlayer;
