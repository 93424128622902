import { Box, Modal } from "@mui/material";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
`;

export const StyledBox = styled(Box)`
  width: 712px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  padding: 52px 0px;
  border-radius: 8px;
  box-shadow: 24;
`;

export const Warning = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: "GothamPro";
  font-size: 43px;
  font-weight: 700;
  margin-bottom: 60px;
`;

export const TextWarning = styled.div`
  font-size: 24px;
  color: #151515;
  font-family: "GothamPro";
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin: 0 10px 54px 10px;
`;

export const ContainerButton = styled.div`
  max-width: 343px;
  width: 100%;
  padding: 0 10px;
`;
