import styled from "styled-components";

export const Container = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media (max-width: 760px) {
    width: 75%;
    height: auto;
  }
  @media (max-width: 375px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb400;
`;

export const Amount = styled.label`
  font-size: 23px;
  color: #000;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1.18px solid white;
  border-top: none;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 397px;
    align-items: center;
    justify-content: center;

    @media (max-width: 760px) {
      height: auto;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    @media (max-width: 475px) {
      height: 250px;
    }
  }
`;

export const TitleRegistration = styled.span`
  color: #787878;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  font-size: 16px;
  margin-top: 10px;
  line-height: 30px;
`;

export const Price = styled.span`
  color: #fff;
  font-weight: 600;
  font-family: "GothamPro", sans-serif;
  font-size: 36px;
  margin-bottom: 10px;
`;

export const Text = styled.span`
  color: #787878;
  font-weight: 700;
  font-family: "GothamPro", sans-serif;
  margin-top: 10px;
`;

export const Total = styled(Text)`
  font-size: 12px;
`;

export const Value = styled(Text)`
  font-size: 18px;
`;
