import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 106px;
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1350px) {
    padding: 20px 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 150px;
  margin-right: 150px;

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const Logo = styled.img`
  width: 76px;
  height: 92px;
`;

export const SideBarContainer = styled.div`
  > svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;
